<template>
    <v-app>
      <v-main>
        <v-container>
          <v-row>
            <v-col>
              <v-card>
                <v-card-title class="headline">Deliveries</v-card-title>
                <v-card-actions>
                  <v-btn color="primary" @click="confirmDeliverAll">Deliver All</v-btn>
                </v-card-actions>
                <v-data-table :headers="headers" :items="deliveries" item-key="deliveryId">
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-btn color="green darken-1" @click="confirmDeliver(item.registration.id)">Deliver</v-btn>
                  </template>
                </v-data-table>
              </v-card>
            </v-col>
          </v-row>
  
          <!-- View Delivery Dialog -->
          <v-dialog v-model="viewDialog" max-width="600px">
            <v-card>
              <v-card-title class="headline">Delivery Details</v-card-title>
              <v-card-text>
                <v-list dense>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Delivery ID</v-list-item-title>
                      <v-list-item-subtitle>{{ selectedDelivery.deliveryId }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>User ID</v-list-item-title>
                      <v-list-item-subtitle>{{ selectedDelivery.userId }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Username</v-list-item-title>
                      <v-list-item-subtitle>{{ selectedDelivery.userName }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Email</v-list-item-title>
                      <v-list-item-subtitle>{{ selectedDelivery.userEmail }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Address</v-list-item-title>
                      <v-list-item-subtitle>{{ selectedDelivery.address }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Contact Number</v-list-item-title>
                      <v-list-item-subtitle>{{ selectedDelivery.contactNumber }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>Delivery Date</v-list-item-title>
                      <v-list-item-subtitle>{{ selectedDelivery.deliveryDate }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeViewDialog">Close</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
  
          <!-- Confirm Deliver Dialog -->
          <v-dialog v-model="confirmDialog" max-width="400px">
            <v-card>
              <v-card-title class="headline">Confirm Deliver</v-card-title>
              <v-card-text>Are you sure you want to mark this delivery as delivered?</v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeConfirmDialog">Cancel</v-btn>
                <v-btn color="green darken-1" text @click="deliverDelivery(confirmDeliveryId)">Deliver</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
  
          <!-- Confirm Deliver All Dialog -->
          <v-dialog v-model="confirmDeliverAllDialog" max-width="400px">
            <v-card>
              <v-card-title class="headline">Confirm Deliver All</v-card-title>
              <v-card-text>Are you sure you want to mark all deliveries as delivered?</v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeConfirmDeliverAllDialog">Cancel</v-btn>
                <v-btn color="green darken-1" text @click="deliverAll">Deliver All</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-container>
      </v-main>
    </v-app>
  </template>
  
  <script>
  import axios from '../axios';
  
  export default {
    data() {
      return {
        deliveries: [],
        confirmDialog: false,
        confirmDeliverAllDialog: false,
        confirmDeliveryId: null,
        viewDialog: false,
        selectedDelivery: {},
        headers: [
          { title: 'Registration ID', value: 'registration.id' },
          { title: 'Username', value: 'user.username' },
          { title: 'Race', value: 'race.raceName' },
          { title: 'Race Type', value: 'registration.racetype' },
          { title: 'Shirt Size', value: 'registration.tshirtSize' },
          { title: 'Email', value: 'user.email' },
          { title: 'Address', value: 'registration.address' },
          { title: 'Contact Number', value: 'registration.contactNumber' },
          { title: 'Completed Date', value: 'registration.completeDate' },
          { title: 'Actions', value: 'actions', sortable: false },
        ],
      };
    },
    created() {
      this.fetchDeliveries();
    },
    methods: {
      fetchDeliveries() {
        axios.get('/api/registrations/delivery').then(response => {
          this.deliveries = response.data;
        });
      },
      viewDelivery(item) {
        this.selectedDelivery = item;
        this.viewDialog = true;
      },
      closeViewDialog() {
        this.viewDialog = false;
        this.selectedDelivery = {};
      },
      confirmDeliver(deliveryId) {
        this.confirmDeliveryId = deliveryId;
        this.confirmDialog = true;
      },
      closeConfirmDialog() {
        this.confirmDialog = false;
      },
      confirmDeliverAll() {
        this.confirmDeliverAllDialog = true;
      },
      closeConfirmDeliverAllDialog() {
        this.confirmDeliverAllDialog = false;
      },
      deliverDelivery(deliveryId) {
        axios.post(`/api/registrations/deliver/${deliveryId}`).then(() => {
          this.fetchDeliveries();
          this.closeConfirmDialog();
        }).catch(error => {
          console.error('There was an error marking the delivery as delivered!', error);
        });
      },
      deliverAll() {
        const deliveryIds = this.deliveries.map(delivery => delivery.registration.id);
        axios.post('/api/registrations/deliver', deliveryIds).then(() => {
          this.fetchDeliveries();
          this.closeConfirmDeliverAllDialog();
        }).catch(error => {
          console.error('There was an error marking all deliveries as delivered!', error);
        });
      },
    },
  };
  </script>
  
  <style scoped>
  /* Add any custom styles here */
  </style>