<template>
    <v-container>
      <v-row>
        <v-col>
          <v-card>
            <v-card-title class="headline">Feed</v-card-title>
            <v-card-text>
              <!-- Content for Feed -->
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  export default {
    name: 'FeedView',
  };
  </script>
  
  <style scoped>
  /* Add any custom styles here */
  </style>