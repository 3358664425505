<template>
  <v-app>
    <v-app-bar app color="primary" prominent>
      <v-app-bar-nav-icon  @click="toggleSidebar"></v-app-bar-nav-icon>
      <v-toolbar-title>Virtual Fitness PH Admin</v-toolbar-title>
    </v-app-bar>
    <SideBar v-model:drawer="drawer" />
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import { ref } from 'vue';
import SideBar from './components/SideBar.vue';

export default {
  components: {
    SideBar,
  },
  setup() {
    const drawer = ref(false);

    const toggleSidebar = () => {
      drawer.value = !drawer.value;
    };

    return {
      drawer,
      toggleSidebar,
    };
  },
};
</script>
<style scoped>
/* Add any custom styles here */
</style>