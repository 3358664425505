<template>
  <v-app>
    <v-main>
      <v-container>
        <v-row>
          <v-col>
            <v-card>
              <v-card-title class="headline">Registrations</v-card-title>
              <v-text-field v-model="search" label="Search" append-icon="mdi-magnify" @keyup="searchRegistrations"></v-text-field>
              <v-tabs v-model="activeTab" background-color="primary" dark>
                <v-tab value="non">Non-Approved</v-tab>
                <v-tab value="all">All Registrations</v-tab>
              </v-tabs>
              <v-tabs-window v-model="activeTab">
                <v-tabs-window-item value="non">
                  <v-data-table :headers="headers" :items="nonApprovedRegistrations" item-key="id">
                    <template v-slot:[`item.actions`]="{ item }">
                      <v-btn color="blue darken-1" @click="editRegistration(item)">Edit</v-btn>
                      <v-btn color="green darken-1" @click="confirmApprove(item.registration.id)">Approve</v-btn>
                      <v-btn color="red darken-1" @click="confirmDelete(item.registration.id)">Delete</v-btn>
                    </template>
                  </v-data-table>
                </v-tabs-window-item>
                <v-tabs-window-item value="all">
                  <v-data-table :headers="headers" :items="allRegistrations" item-key="id">
                    <template v-slot:[`item.actions`]="{ item }">
                      <v-btn color="blue darken-1" @click="editRegistration(item)">Edit</v-btn>
                      <v-btn color="red darken-1" @click="confirmDelete(item.registration.id)">Delete</v-btn>
                    </template>
                  </v-data-table>
                </v-tabs-window-item>
              </v-tabs-window>
            </v-card>
          </v-col>
        </v-row>

        <!-- Edit Registration Dialog -->
        <v-dialog v-model="editDialog" max-width="600px">
          <v-card>
            <v-card-title class="headline">Edit Registration</v-card-title>
            <v-card-text>
              <v-list dense>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Registration ID</v-list-item-title>
                    <v-list-item-subtitle>{{ selectedRegistration.id }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Race Type</v-list-item-title>
                    <v-select v-model="selectedRegistration.racetype" :items="raceTypes" label="Race Type"></v-select>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>T-Shirt Size</v-list-item-title>
                    <v-select v-model="selectedRegistration.tshirtSize" :items="tshirtSizes" label="T-Shirt Size"></v-select>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>Race Distance</v-list-item-title>
                    <v-select v-model="selectedRegistration.raceDistance" :items="raceDistances" label="Race Distance"></v-select>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeEditDialog">Cancel</v-btn>
              <v-btn color="green darken-1" text @click="saveRegistration">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- Confirm Approve Dialog -->
        <v-dialog v-model="confirmDialog" max-width="400px">
          <v-card>
            <v-card-title class="headline">Confirm Approve</v-card-title>
            <v-card-text>Are you sure you want to approve this registration?</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeConfirmDialog">Cancel</v-btn>
              <v-btn color="green darken-1" text @click="approveRegistration(confirmRegistrationId)">Approve</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- Confirm Delete Dialog -->
        <v-dialog v-model="deleteDialog" max-width="400px">
          <v-card>
            <v-card-title class="headline">Confirm Delete</v-card-title>
            <v-card-text>Are you sure you want to delete this registration?</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDeleteDialog">Cancel</v-btn>
              <v-btn color="red darken-1" text @click="deleteRegistration(deleteRegistrationId)">Delete</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import axios from '../axios';

export default {
  data() {
    return {
      activeTab: 0,
      search: '',
      nonApprovedRegistrations: [],
      allRegistrations: [],
      confirmDialog: false,
      confirmRegistrationId: null,
      deleteDialog: false,
      deleteRegistrationId: null,
      editDialog: false,
      selectedRegistration: {},
      raceTypes: [],
      tshirtSizes: ['NONE', 'XS', 'S', 'M', 'L', 'XL', 'XXL'],
      raceDistances: [],
      headers: [
        { title: 'Registration ID', value: 'registration.id' },
        { title: 'User Name', value: 'user.username' },
        { title: 'User Email', value: 'user.email' },
        { title: 'Race', value: 'race.raceName' },
        { title: 'Race Type', value: 'registration.racetype' },
        { title: 'Contact Number', value: 'registration.contactNumber' },
        { title: 'T-Shirt Size', value: 'registration.tshirtSize' },
        { title: 'Race Distance', value: 'registration.raceDistance' },
        { title: 'Registration Date', value: 'registration.registrationDate' },
        { title: 'Actions', value: 'actions', sortable: false },
      ],
    };
  },
  created() {
    this.fetchNonApprovedRegistrations();
    this.fetchAllRegistrations();
  },
  methods: {
    fetchNonApprovedRegistrations() {
      axios.get('/api/registrations/non-approved').then(response => {
        this.nonApprovedRegistrations = response.data;
      });
    },
    fetchAllRegistrations() {
      axios.get('/api/registrations/all').then(response => {
        this.allRegistrations = response.data;
      });
    },
    searchRegistrations() {
      const query = this.search.toLowerCase();
      this.nonApprovedRegistrations = this.nonApprovedRegistrations.filter(registration =>
        Object.values(registration).some(value =>
          String(value).toLowerCase().includes(query)
        )
      );
      this.allRegistrations = this.allRegistrations.filter(registration =>
        Object.values(registration).some(value =>
          String(value).toLowerCase().includes(query)
        )
      );
    },
    editRegistration(item) {
      this.selectedRegistration = { ...item.registration };
      this.raceTypes = item.race.racetypes.map(type => type.name);
      this.raceDistances = item.race.distances.split(',').map(Number);
      this.editDialog = true;
    },
    closeEditDialog() {
      this.editDialog = false;
      this.selectedRegistration = {};
    },
    saveRegistration() {
      axios.put(`/api/registrations/${this.selectedRegistration.id}`, this.selectedRegistration).then(() => {
        this.fetchNonApprovedRegistrations();
        this.fetchAllRegistrations();
        this.closeEditDialog();
      }).catch(error => {
        console.error('There was an error saving the registration!', error);
      });
    },
    confirmApprove(registrationId) {
      this.confirmRegistrationId = registrationId;
      this.confirmDialog = true;
    },
    closeConfirmDialog() {
      this.confirmDialog = false;
    },
    approveRegistration(registrationId) {
      axios.post(`/api/registrations/approve/${registrationId}`).then(() => {
        this.fetchNonApprovedRegistrations();
        this.fetchAllRegistrations();
        this.closeConfirmDialog();
      }).catch(error => {
        console.error('There was an error approving the registration!', error);
      });
    },
    confirmDelete(registrationId) {
      this.deleteRegistrationId = registrationId;
      this.deleteDialog = true;
    },
    closeDeleteDialog() {
      this.deleteDialog = false;
    },
    deleteRegistration(registrationId) {
      axios.post(`/api/registrations/reject/${registrationId}`).then(() => {
        this.fetchNonApprovedRegistrations();
        this.fetchAllRegistrations();
        this.closeDeleteDialog();
      }).catch(error => {
        console.error('There was an error deleting the registration!', error);
      });
    },
  },
};
</script>

<style scoped>
/* Add any custom styles here */
</style>